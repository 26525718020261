<template>
  <v-row>
    <!-- page header -->
    <v-col cols="12">
      <v-toolbar flat rounded :outlined="$vuetify.theme.dark">
        <v-toolbar-title class="display-1 font-weight-medium">
          Package Categories</v-toolbar-title
        >

        <v-spacer />

        <v-toolbar-items>
          <div class="pageHeaderListWrapper" v-if="packageCategories.length">
            <v-list-item three-line dense class="pa-0">
              <v-list-item-content>
                <v-list-item-title
                  >Total Package Categories:
                  {{ packageCategories.length }}</v-list-item-title
                >
                <!-- <v-list-item-subtitle>
                  Available Packages:
                  {{ packages.filter((i) => i.available).length }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Non-Available Packages:
                  {{ packages.filter((i) => !i.available).length }}
                </v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </div>
          <v-btn icon color="primary" to="/packages/categories/new">
            <v-icon>mdi-plus-circle-outline</v-icon></v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <v-divider v-if="!$vuetify.theme.dark" />
    </v-col>
    <!-- page header -->
    <!-- event reports -->
    <v-col cols="12">
      <v-data-table
        dense
        :headers="[{ text: 'Title', value: 'title' }]"
        :items="packageCategories"
        :items-per-page="-1"
        class="border"
      >
        <!-- title -->
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Package Categories</v-toolbar-title>
          </v-toolbar>
        </template>
        <!-- items -->
        <template v-slot:item="data">
          <template>
            <tr
              @click="$router.push(`/packages/categories/${data.item.id}`)"
              style="cursor: pointer"
            >
              <td>
                {{ data.item.title }}
              </td>
            </tr>
          </template>
        </template>
        <!-- no data -->
        <template v-slot:no-data>
          <span class="font-weight-bold"> No Packages Created</span>
        </template>
      </v-data-table>
    </v-col>
    <!-- packages -->
  </v-row>
</template>

<script>
import { mainApp } from "@/firebase";
export default {
  firestore: {
    packageCategories: mainApp
      .firestore()
      .collection("packageCategories")
      .orderBy("title"),
  },
  data: () => ({
    packageCategories: [],
  }),
};
</script>